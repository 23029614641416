<template>
  <a-spin :spinning="ifSub">
    <a-form :form="form">
      <a-row>
        <a-col :span="24">
          <a-form-item label="负责人"
                       :label-col="labelCol"
                       :wrapper-col="wrapperCol">
            <a-tree-select
              v-decorator="[
                'userId',
                {
                  validateTrigger: ['change'],
                  rules: [{
                    type: 'string',
                    required: true,
                    message: '请选择负责人',
                  }]
                }
              ]"
              treeDefaultExpandAll
              treeNodeFilterProp="title"
              style="width: 100%"
              :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
              :treeData="orgUserTreeData"
              placeholder="请选择负责人"
            />
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="车辆" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-select v-decorator="['carId', { rules: [{ required: true, message: '请选择车辆' }] }]" placeholder="请选择车辆">
              <a-select-option
                v-for="item in carList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.platNumber }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="保险金额" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-input v-decorator="['insuranceAmount']" placeholder="请输入保险金额"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="开始时间" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-date-picker
              style="width: 100%;"
              :showTime="{ format: 'YYYY-MM-DD' }"
              format="YYYY-MM-DD"
              v-decorator="['insuranceStartDate', { rules: [{ required: true, message: '请选择开始时间' }] }]"
              placeholder="请选择开始时间"
            ></a-date-picker>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="到期时间" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-date-picker
              style="width: 100%;"
              :showTime="{ format: 'YYYY-MM-DD' }"
              format="YYYY-MM-DD"
              v-decorator="['insuranceEndDate', { rules: [{ required: true, message: '请选择到期时间' }] }]"
              placeholder="请选择到期时间"
            ></a-date-picker>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-spin>
</template>

<script>
  import { formVO } from './common/FormVO'
  import entityCURDCallback from '../../../common/mixins/entityCURDCallback'
  import SERVICE_URLS from '@/api/service.url'
  import { statusList } from './common/common'
  import moment from 'moment'

  export default {
    name: 'CarInfoForm',
    mixins: [entityCURDCallback],
    components: {},
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.csgz.carInsuranceApi,
        initFormItem: formVO(),
        showFormItem: false,
        statusList: statusList(),
        carList: [],
        orgUserTreeData: []
      }
    },
    computed: {
      currentUser () {
        return this.$store.getters.currentUser
      }
    },
    mounted () {
      this.loadCarInfoList()
      this.loadCheckUserList()
    },
    methods: {
      loadCarInfoList () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.carInfoApi.select,
          noTips: true,
          success: (data) => {
            this.carList = data.body
          }
        })
      },
      setFields (values) {
        Object.assign(this.formItem, values)
        this.loadCheckUserList()
        this.form.setFieldsValue({
          carId: this.formItem.carId,
          insuranceStartDate: moment(this.formItem.insuranceStartDate),
          insuranceEndDate: moment(this.formItem.insuranceEndDate),
          insuranceAmount: this.formItem.insuranceAmount
        })
      },
      setVOFields (values) {
        Object.assign(this.formItem, values)
        var userkey = values.userId.split('_')[2]
        this.formItem.userId = userkey
      },
      loadCheckUserList () {
        this.$http(this, {
          url: SERVICE_URLS.organization.orgUserTree,
          data: {
            authPrefix: 'org_',
            chooseType: 'RADIO'
          },
          noTips: true,
          success: (data) => {
            this.orgUserTreeData = data.body
            if (this.type === 'add') {
              /*默认设置执行人*/
              this.$nextTick(() => {
                const dutyUserKey = this.getSelectKey(this.orgUserTreeData, this.currentUser.id)
                if (dutyUserKey) {
                  this.form.setFieldsValue({
                    'userId': dutyUserKey
                  })
                }
              })
            } else {
              /*TODO 1.为啥在和处理？*/
              /*处理下拉树KEY*/
              var dutyUserKey = this.getSelectKey(this.orgUserTreeData, this.formItem.userId)
              if (dutyUserKey) {
                this.form.setFieldsValue({
                  'userId': dutyUserKey
                })
              }
            }
          }
        })
      },
      getSelectKey (list, id) {
        var key
        for (let i = 0; i < list.length; i++) {
          const item = list[i]
          const tempKey = item.key
          if (tempKey.substring('USER_') !== -1 && Number(tempKey.split('_')[2]) === id) {
            key = tempKey
            return key
          } else if (item.children.length > 0) {
            key = this.getSelectKey(item.children, id)
          }
          if (key) return key
        }
      },
      onChangeStartTime (date) {
        this.form.setFieldsValue({
          insuranceEndDate: moment(date).add(1, 'year')
        })
      }
    }
  }
</script>

<style scoped></style>
